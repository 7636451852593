var memoize = function (f) {
    var state = { called: false };
    return function () {
        if (state.called) {
            return state.result;
        }
        else {
            state = { called: true, result: f() };
            return state.result;
        }
    };
};
export default memoize;

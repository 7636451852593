// Provides a WebWorker-based timer implementation which doesn't get
// wakeup-limited by the browser when the tab is running in the background.
//
// Relies on timer-worker.js to service the underlying timing requests.
import * as React from "react";
export var TimerContext = React.createContext({
    setTimeout: function (_fn, _delay) { return 0; },
    clearTimeout: function (_id) { },
    setInterval: function (_fn, _interval) { return 0; },
    clearInterval: function (_id) { },
});
var _TimerProvider = function (props) {
    var _a = React.useState(null), worker = _a[0], setWorker = _a[1];
    var timeoutId = React.useRef(0);
    var callbacks = React.useRef(new Map());
    React.useEffect(function () {
        var timerWorker = new Worker("timer-worker.js");
        timerWorker.addEventListener("message", function (evt) {
            var data = evt.data;
            var id = data.id;
            if (callbacks.current.has(id)) {
                callbacks.current.get(id)();
            }
            if (data.variant === "timeout") {
                callbacks.current.delete(id);
            }
        });
        setWorker(timerWorker);
        return function () {
            timerWorker.terminate();
        };
    }, []);
    var setTimeout = function (fn, delay) {
        timeoutId.current += 1;
        delay = delay === undefined ? 0 : delay;
        var id = timeoutId.current;
        callbacks.current.set(id, fn);
        if (worker !== null) {
            worker.postMessage({ command: "setTimeout", id: id, timeout: delay });
        }
        return id;
    };
    var clearTimeout = function (id) {
        worker.postMessage({ command: "clearTimeout", id: id });
        callbacks.current.delete(id);
    };
    var setInterval = function (fn, interval) {
        timeoutId.current += 1;
        interval = interval === undefined ? 0 : interval;
        var id = timeoutId.current;
        callbacks.current.set(id, fn);
        if (worker !== null) {
            worker.postMessage({ command: "setInterval", id: id, interval: interval });
        }
        return id;
    };
    var clearInterval = function (id) {
        if (worker !== null) {
            worker.postMessage({ command: "clearInterval", id: id });
        }
        callbacks.current.delete(id);
    };
    return (React.createElement(TimerContext.Provider, { value: { setTimeout: setTimeout, clearTimeout: clearTimeout, setInterval: setInterval, clearInterval: clearInterval } }, props.children));
};
var TimerProvider = function (props) { return (React.createElement(_TimerProvider, null, props.children)); };
export default TimerProvider;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import IconButton from "./IconButton";
import PaperPlane from "./icons/PaperPlane";
var ChatBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 25px;\n  border: 0px;\n  outline: none;\n  background-color: #eee;\n  display: flex;\n  flex-direction: row;\n  padding-left: 1em;\n  padding-right: 1em;\n  margin: 0.5em;\n"], ["\n  border-radius: 25px;\n  border: 0px;\n  outline: none;\n  background-color: #eee;\n  display: flex;\n  flex-direction: row;\n  padding-left: 1em;\n  padding-right: 1em;\n  margin: 0.5em;\n"])));
var Input = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  outline: none;\n  background-color: #eee;\n  border: none;\n  margin-top: 0.6em;\n  margin-bottom: 0.6em;\n  font-size: 14px;\n  line-height: 14px;\n  height: 14px;\n  flex: 1;\n"], ["\n  outline: none;\n  background-color: #eee;\n  border: none;\n  margin-top: 0.6em;\n  margin-bottom: 0.6em;\n  font-size: 14px;\n  line-height: 14px;\n  height: 14px;\n  flex: 1;\n"])));
var ChatInput = function (props) {
    var _a = React.useState(""), draft = _a[0], setDraft = _a[1];
    var handleSubmit = function (event) {
        event.preventDefault();
        if (draft.length > 0) {
            props.onSubmit(draft);
            setDraft("");
        }
    };
    var disabled = draft === "";
    return (React.createElement("form", { onSubmit: handleSubmit, autoComplete: "off" },
        React.createElement(ChatBox, null,
            React.createElement(Input, { type: "text", placeholder: "type message here", value: draft, onChange: function (e) { return setDraft(e.target.value); } }),
            React.createElement(IconButton, { type: "submit", style: {
                    opacity: disabled ? 0 : 1,
                    transform: disabled ? "translate(1em, 0)" : "none",
                    margin: "auto",
                }, disabled: disabled },
                React.createElement(PaperPlane, { width: "16px" })))));
};
export default ChatInput;
var templateObject_1, templateObject_2;

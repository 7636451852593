import * as React from "react";
import GameMode from "./GameMode";
import GameStatisticsButton from "./GameStatisticsButton";
import SettingsButton from "./SettingsButton";
var Header = function (props) { return (React.createElement("div", null,
    React.createElement("h1", null,
        React.createElement(GameMode, { gameMode: props.gameMode }),
        "\u00A0",
        React.createElement(SettingsButton, null),
        React.createElement(GameStatisticsButton, null)),
    props.chatLink !== undefined && props.chatLink !== null ? (React.createElement("p", null,
        "Join the chat at",
        " ",
        React.createElement("a", { href: props.chatLink, target: "_blank", rel: "noreferrer" }, props.chatLink))) : null)); };
export default Header;

import * as React from "react";
import InlineCard from "./InlineCard";
import preloadedCards from "./preloadedCards";
var TrumpE = function (props) {
    var trump = props.trump;
    if ("Standard" in trump) {
        var _a = trump.Standard, suit_1 = _a.suit, rank_1 = _a.number;
        var card = preloadedCards.filter(function (v) { return v.typ === suit_1 && v.number === rank_1; })[0].value;
        return (React.createElement("div", { className: "trump" },
            "The trump suit is ",
            React.createElement(InlineCard, { card: card }),
            " (rank ",
            rank_1,
            ")"));
    }
    else if (trump.NoTrump.number !== undefined &&
        trump.NoTrump.number !== null) {
        return React.createElement("div", { className: "trump" },
            "No trump, rank ",
            trump.NoTrump.number);
    }
    else {
        return React.createElement("div", { className: "trump" }, "No trump");
    }
};
export default TrumpE;

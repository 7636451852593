var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import gameStatistics from "./state/GameStatistics";
import settings from "./state/Settings";
import { combineState, noPersistence } from "./State";
import { stringLocalStorageState, numberLocalStorageState, } from "./localStorageState";
var appState = combineState({
    settings: settings,
    gameStatistics: gameStatistics,
    connected: noPersistence(function () { return false; }),
    everConnected: noPersistence(function () { return false; }),
    roomName: noPersistence(function () { return window.location.hash.slice(1, 17); }),
    name: stringLocalStorageState("name"),
    changeLogLastViewed: numberLocalStorageState("change_log_last_viewed"),
    gameState: noPersistence(function () { return null; }),
    headerMessages: noPersistence(function () { return []; }),
    errors: noPersistence(function () { return []; }),
    messages: noPersistence(function () { return []; }),
    confetti: noPersistence(function () { return null; }),
});
export var AppStateContext = React.createContext({
    state: appState.loadDefault(),
    updateState: function () { },
});
export var SettingsContext = React.createContext(appState.loadDefault().settings);
export var AppStateConsumer = AppStateContext.Consumer;
var AppStateProvider = function (props) {
    var _a = React.useState(function () {
        return appState.loadDefault();
    }), state = _a[0], setState = _a[1];
    var updateState = function (newState) {
        setState(function (s) {
            var combined = __assign(__assign({}, s), newState);
            appState.persist(state, combined);
            return combined;
        });
    };
    return (React.createElement(AppStateContext.Provider, { value: { state: state, updateState: updateState } },
        React.createElement(SettingsContext.Provider, { value: state.settings }, props.children)));
};
export default AppStateProvider;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import ReactTooltip from "react-tooltip";
import * as ReactModal from "react-modal";
import Header from "./Header";
import Beeper from "./Beeper";
import Trump from "./Trump";
import Friends from "./Friends";
import Trick from "./Trick";
import Cards from "./Cards";
import Points, { calculatePoints, ProgressBarDisplay } from "./Points";
import LabeledPlay from "./LabeledPlay";
import Players from "./Players";
import ArrayUtils from "./util/array";
import AutoPlayButton from "./AutoPlayButton";
import BeepButton from "./BeepButton";
import { WebsocketContext } from "./WebsocketProvider";
import { SettingsContext } from "./AppStateProvider";
import WasmContext from "./WasmContext";
import InlineCard from "./InlineCard";
var contentStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};
var Play = function (props) {
    var send = React.useContext(WebsocketContext).send;
    var settings = React.useContext(SettingsContext);
    var _a = React.useState([]), selected = _a[0], setSelected = _a[1];
    var _b = React.useState([]), grouping = _b[0], setGrouping = _b[1];
    var _c = React.useContext(WasmContext), findViablePlays = _c.findViablePlays, canPlayCards = _c.canPlayCards, nextThresholdReachable = _c.nextThresholdReachable, sortAndGroupCards = _c.sortAndGroupCards;
    var playCards = function () {
        send({ Action: { PlayCardsWithHint: [selected, grouping[0].grouping] } });
        setSelected([]);
        setGrouping([]);
    };
    var sendEvent = function (event) { return function () { return send(event); }; };
    var takeBackCards = sendEvent({ Action: "TakeBackCards" });
    var endTrick = sendEvent({ Action: "EndTrick" });
    var endGameEarly = sendEvent({ Action: "EndGameEarly" });
    var startNewGame = sendEvent({ Action: "StartNewGame" });
    var playPhase = props.playPhase;
    // TODO: instead of telling who the player is by checking the name, pass in
    // the Player object
    var isSpectator = true;
    var currentPlayer = playPhase.propagated.players.find(function (p) { return p.name === props.name; });
    if (currentPlayer === undefined) {
        currentPlayer = playPhase.propagated.observers.find(function (p) { return p.name === props.name; });
    }
    else {
        isSpectator = false;
    }
    if (currentPlayer === undefined) {
        currentPlayer = {
            id: -1,
            name: props.name,
            level: "",
            metalevel: 0,
        };
    }
    React.useEffect(function () {
        // When the hands change, our `selected` cards may become invalid, since we
        // could have raced and selected cards that we just played.
        //
        // In that case, let's fix the selected cards.
        var hand = currentPlayer.id in playPhase.hands.hands
            ? __assign({}, playPhase.hands.hands[currentPlayer.id]) : {};
        selected.forEach(function (card) {
            if (card in hand) {
                hand[card] = hand[card] - 1;
            }
            else {
                hand[card] = -1;
            }
        });
        var toRemove = Object.entries(hand)
            .filter(function (x) { return x[1] < 0; })
            .map(function (x) { return x[0]; });
        var newSelected = ArrayUtils.minus(selected, toRemove);
        if (toRemove.length > 0) {
            setSelected(newSelected);
            setGrouping(findViablePlays(playPhase.trump, playPhase.propagated.tractor_requirements, newSelected));
        }
    }, [playPhase.hands.hands, currentPlayer.id, selected]);
    var nextPlayer = playPhase.trick.player_queue[0];
    var lastPlay = playPhase.trick.played_cards[playPhase.trick.played_cards.length - 1];
    var canPlay = React.useMemo(function () {
        if (!isSpectator) {
            var playable = canPlayCards({
                trick: playPhase.trick,
                id: currentPlayer.id,
                hands: playPhase.hands,
                cards: selected,
                trick_draw_policy: playPhase.propagated.trick_draw_policy,
            });
            // In order to play the first trick, the grouping must be disambiguated!
            if (lastPlay === undefined) {
                playable = playable && grouping.length === 1;
            }
            playable = playable && !playPhase.game_ended_early;
            return playable;
        }
    }, [
        playPhase.trick,
        currentPlayer.id,
        playPhase.hands,
        selected,
        playPhase.propagated.trick_draw_policy,
        isSpectator,
        lastPlay,
        playPhase.game_ended_early,
        grouping,
    ]);
    var isCurrentPlayerTurn = currentPlayer.id === nextPlayer;
    var canTakeBack = lastPlay !== undefined &&
        currentPlayer.id === lastPlay.id &&
        !playPhase.game_ended_early;
    var shouldBeBeeping = props.beepOnTurn && isCurrentPlayerTurn && !playPhase.game_ended_early;
    var remainingCardsInHands = ArrayUtils.sum(Object.values(playPhase.hands.hands).map(function (playerHand) {
        return ArrayUtils.sum(Object.values(playerHand));
    }));
    var _d = calculatePoints(playPhase.propagated.players, playPhase.landlords_team, playPhase.points, playPhase.penalties), totalPointsPlayed = _d.totalPointsPlayed, nonLandlordPointsWithPenalties = _d.nonLandlordPointsWithPenalties;
    var noCardsLeft = remainingCardsInHands === 0 && playPhase.trick.played_cards.length === 0;
    var canFinish = noCardsLeft || playPhase.game_ended_early;
    var canEndGameEarly = !canFinish &&
        !nextThresholdReachable({
            decks: playPhase.decks,
            params: playPhase.propagated.game_scoring_parameters,
            non_landlord_points: nonLandlordPointsWithPenalties,
            observed_points: totalPointsPlayed,
        });
    var landlordSuffix = playPhase.propagated.landlord_emoji !== undefined &&
        playPhase.propagated.landlord_emoji !== null &&
        playPhase.propagated.landlord_emoji !== ""
        ? playPhase.propagated.landlord_emoji
        : "(当庄)";
    var landlordTeamSize = playPhase.landlords_team.length;
    var configFriendTeamSize = 0;
    var smallerTeamSize = false;
    if (playPhase.game_mode !== "Tractor") {
        configFriendTeamSize =
            playPhase.game_mode.FindingFriends.num_friends != null
                ? playPhase.game_mode.FindingFriends.num_friends + 1
                : playPhase.propagated.players.length / 2;
        smallerTeamSize = landlordTeamSize < configFriendTeamSize;
    }
    var getCardsFromHand = function (pid) {
        var cardsInHand = pid in playPhase.hands.hands
            ? Object.entries(playPhase.hands.hands[pid]).flatMap(function (_a) {
                var c = _a[0], ct = _a[1];
                return Array(ct).fill(c);
            })
            : [];
        return sortAndGroupCards({
            cards: cardsInHand,
            trump: props.playPhase.trump,
        });
    };
    return (React.createElement("div", null,
        shouldBeBeeping ? React.createElement(Beeper, null) : null,
        React.createElement(Header, { gameMode: playPhase.propagated.game_mode, chatLink: playPhase.propagated.chat_link }),
        React.createElement(Players, { players: playPhase.propagated.players, observers: playPhase.propagated.observers, landlord: playPhase.landlord, landlords_team: playPhase.landlords_team, name: props.name, next: nextPlayer }),
        React.createElement(Trump, { trump: playPhase.trump }),
        React.createElement(Friends, { gameMode: playPhase.game_mode, showPlayed: true }),
        playPhase.removed_cards.length > 0 ? (React.createElement("p", null,
            "Note:",
            " ",
            playPhase.removed_cards.map(function (c) { return (React.createElement(InlineCard, { key: c, card: c })); }),
            " ",
            "have been removed from the deck")) : null,
        settings.showPointsAboveGame && (React.createElement(ProgressBarDisplay, { points: playPhase.points, penalties: playPhase.penalties, decks: playPhase.decks, trump: playPhase.trump, players: playPhase.propagated.players, landlordTeam: playPhase.landlords_team, landlord: playPhase.landlord, hideLandlordPoints: playPhase.propagated.hide_landlord_points, gameScoringParameters: playPhase.propagated.game_scoring_parameters, smallerTeamSize: smallerTeamSize })),
        React.createElement(Trick, { trick: playPhase.trick, players: playPhase.propagated.players, landlord: playPhase.landlord, landlord_suffix: landlordSuffix, landlords_team: playPhase.landlords_team, next: nextPlayer, name: props.name, showTrickInPlayerOrder: props.showTrickInPlayerOrder }),
        React.createElement(AutoPlayButton, { onSubmit: playCards, playDescription: grouping.length === 1 && lastPlay === undefined
                ? grouping[0].description
                : null, canSubmit: canPlay, currentWinner: playPhase.trick.current_winner, unsetAutoPlayWhenWinnerChanges: props.unsetAutoPlayWhenWinnerChanges, isCurrentPlayerTurn: isCurrentPlayerTurn }),
        playPhase.propagated.play_takeback_policy === "AllowPlayTakeback" && (React.createElement("button", { className: "big", onClick: takeBackCards, disabled: !canTakeBack }, "Take back last play")),
        React.createElement("button", { className: "big", onClick: endTrick, disabled: playPhase.trick.player_queue.length > 0 || playPhase.game_ended_early }, "Finish trick"),
        canEndGameEarly && (React.createElement("button", { className: "big", onClick: function () {
                confirm("Do you want to end the game early? There may still be points in the bottom...") && endGameEarly();
            } }, "End game early")),
        canFinish && (React.createElement("button", { className: "big", onClick: startNewGame }, "Finish game")),
        React.createElement(BeepButton, null),
        canFinish && !noCardsLeft && (React.createElement("div", null,
            React.createElement("p", null, "Cards remaining (that were not played):"),
            playPhase.propagated.players.map(function (p) { return (React.createElement(LabeledPlay, { key: p.id, trump: playPhase.trump, label: p.name, cards: getCardsFromHand(p.id).flatMap(function (g) { return g.cards; }) })); }))),
        !canFinish && (React.createElement(React.Fragment, null,
            playPhase.trick.trick_format !== null &&
                !isSpectator &&
                playPhase.trick.player_queue.includes(currentPlayer.id) ? (React.createElement(TrickFormatHelper, { format: playPhase.trick.trick_format, hands: playPhase.hands, playerId: currentPlayer.id, trickDrawPolicy: playPhase.propagated.trick_draw_policy, setSelected: function (newSelected) {
                    setSelected(newSelected);
                    setGrouping(findViablePlays(playPhase.trump, playPhase.propagated.tractor_requirements, newSelected));
                } })) : null,
            lastPlay === undefined &&
                isCurrentPlayerTurn &&
                grouping.length > 1 && (React.createElement("div", null,
                React.createElement("p", null, "It looks like you are making a play that can be interpreted in multiple ways!"),
                React.createElement("p", null, "Which of the following did you mean?"),
                grouping.map(function (g, gidx) { return (React.createElement("button", { key: gidx, onClick: function (evt) {
                        evt.preventDefault();
                        setGrouping([g]);
                    }, className: "big" }, g.description)); }))),
            React.createElement(Cards, { hands: playPhase.hands, playerId: currentPlayer.id, trump: playPhase.trump, selectedCards: selected, onSelect: function (newSelected) {
                    setSelected(newSelected);
                    setGrouping(findViablePlays(playPhase.trump, playPhase.propagated.tractor_requirements, newSelected));
                }, notifyEmpty: isCurrentPlayerTurn }))),
        playPhase.last_trick !== undefined &&
            playPhase.last_trick !== null &&
            props.showLastTrick ? (React.createElement("div", null,
            React.createElement("p", null, "Previous trick"),
            React.createElement(Trick, { trick: playPhase.last_trick, players: playPhase.propagated.players, landlord: playPhase.landlord, landlord_suffix: landlordSuffix, landlords_team: playPhase.landlords_team, name: props.name, showTrickInPlayerOrder: props.showTrickInPlayerOrder }))) : null,
        React.createElement(Points, { points: playPhase.points, penalties: playPhase.penalties, decks: playPhase.decks, players: playPhase.propagated.players, landlordTeam: playPhase.landlords_team, landlord: playPhase.landlord, trump: playPhase.trump, hideLandlordPoints: playPhase.propagated.hide_landlord_points, gameScoringParameters: playPhase.propagated.game_scoring_parameters, smallerTeamSize: smallerTeamSize }),
        React.createElement(LabeledPlay, { trump: playPhase.trump, className: "kitty", cards: playPhase.kitty, label: "\u5E95\u724C" })));
};
var HelperContents = function (props) {
    var decomposeTrickFormat = React.useContext(WasmContext).decomposeTrickFormat;
    var decomp = React.useMemo(function () {
        return decomposeTrickFormat({
            trick_format: props.format,
            hands: props.hands,
            player_id: props.playerId,
            trick_draw_policy: props.trickDrawPolicy,
        });
    }, [props.format, props.hands, props.playerId, props.trickDrawPolicy]);
    var trickSuit = props.format.suit;
    var bestMatch = decomp.findIndex(function (d) { return d.playable.length > 0; });
    var modalContents = (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "In order to win, you have to play ",
            decomp[0].description,
            " in ",
            trickSuit),
        decomp[0].playable.length > 0 && (React.createElement("p", null,
            "It looks like you are able to match this format, e.g. with",
            " ",
            React.createElement("span", { style: { cursor: "pointer" }, onClick: function () { return props.setSelected(decomp[0].playable); } }, decomp[0].playable.map(function (c, cidx) { return (React.createElement(InlineCard, { key: cidx, card: c })); })))),
        decomp.length > 1 && props.trickDrawPolicy !== "NoFormatBasedDraw" && (React.createElement(React.Fragment, null,
            React.createElement("p", null,
                "If you can't play that, but you ",
                React.createElement("em", null, "can"),
                " play one of the following, you have to play it"),
            React.createElement("ol", null, decomp.slice(1).map(function (d, idx) { return (React.createElement("li", { key: idx, style: {
                    fontWeight: idx === bestMatch - 1 ? "bold" : "normal",
                } },
                d.description,
                " in ",
                trickSuit,
                idx === bestMatch - 1 && (React.createElement(React.Fragment, null,
                    " ",
                    React.createElement("span", { style: { cursor: "pointer" }, onClick: function () { return props.setSelected(d.playable); } },
                        "(for example:",
                        " ",
                        d.playable.map(function (c, cidx) { return (React.createElement(InlineCard, { key: cidx, card: c })); }),
                        ")"))))); })))),
        React.createElement("p", { style: {
                fontWeight: bestMatch < 0 ? "bold" : "normal",
            } },
            "Otherwise, you have to play as many ",
            trickSuit,
            " as you can. The remaining cards can be anything."),
        trickSuit !== "Trump" && (React.createElement("p", null,
            "If you have no cards in ",
            trickSuit,
            ", you can play",
            " ",
            decomp[0].description,
            " in Trump to potentially win the trick."))));
    return modalContents;
};
var TrickFormatHelper = function (props) {
    var decomposeTrickFormat = React.useContext(WasmContext).decomposeTrickFormat;
    var _a = React.useState(false), modalOpen = _a[0], setModalOpen = _a[1];
    var _b = React.useState(""), message = _b[0], setMessage = _b[1];
    React.useEffect(function () {
        setMessage("");
    }, [props.hands]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ReactTooltip, { id: "helpTip", place: "top", effect: "solid" }, "Get help on what you can play"),
        React.createElement("button", { "data-tip": true, "data-for": "helpTip", className: "big", onClick: function (evt) {
                evt.preventDefault();
                setModalOpen(true);
            } }, "?"),
        React.createElement(ReactTooltip, { id: "suggestTip", place: "top", effect: "solid" }, "Suggest a play (not guaranteed to succeed)"),
        React.createElement("button", { "data-tip": true, "data-for": "suggestTip", className: "big", onClick: function (evt) {
                evt.preventDefault();
                var decomp = decomposeTrickFormat({
                    trick_format: props.format,
                    hands: props.hands,
                    player_id: props.playerId,
                    trick_draw_policy: props.trickDrawPolicy,
                });
                var bestMatch = decomp.findIndex(function (d) { return d.playable.length > 0; });
                if (bestMatch >= 0) {
                    props.setSelected(decomp[bestMatch].playable);
                    setMessage("success");
                    setTimeout(function () { return setMessage(""); }, 500);
                }
                else {
                    setMessage("cannot suggest a play");
                    setTimeout(function () { return setMessage(""); }, 2000);
                }
            } }, "\u2728"),
        React.createElement("span", { style: { color: "red" }, onClick: function () { return setMessage(""); } }, message),
        React.createElement(ReactModal, { isOpen: modalOpen, onRequestClose: function () { return setModalOpen(false); }, shouldCloseOnOverlayClick: true, shouldCloseOnEsc: true, style: { content: contentStyle } }, modalOpen && (React.createElement(HelperContents, { format: props.format, hands: props.hands, playerId: props.playerId, trickDrawPolicy: props.trickDrawPolicy, setSelected: function (sel) {
                props.setSelected(sel);
                setModalOpen(false);
            } })))));
};
export default Play;

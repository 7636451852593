import * as React from "react";
import ArrayUtils from "./util/array";
import WasmContext from "./WasmContext";
var KittySizeSelector = function (props) {
    var computeDeckLen = React.useContext(WasmContext).computeDeckLen;
    var handleChange = function (e) {
        var newKittySize = e.target.value === "" ? null : parseInt(e.target.value, 10);
        props.onChange(newKittySize);
    };
    var deckLen = computeDeckLen(props.decks);
    var kittyOffset = deckLen % props.numPlayers;
    var defaultOptions = [
        kittyOffset,
        kittyOffset + props.numPlayers,
        kittyOffset + 2 * props.numPlayers,
        kittyOffset + 3 * props.numPlayers,
        kittyOffset + 4 * props.numPlayers,
    ];
    var potentialOptions = ArrayUtils.range(kittyOffset + 4 * props.numPlayers, function (v) { return v; });
    var options = potentialOptions.filter(function (v) {
        return !defaultOptions.includes(v) &&
            v < deckLen - props.numPlayers &&
            // Note: this isn't quite right, but it seems fine for the common case of no short decks.
            (deckLen - v) % props.numPlayers <= props.decks.length * 4;
    });
    return (React.createElement("div", null,
        React.createElement("label", null,
            "Number of cards in the bottom:",
            " ",
            React.createElement("select", { value: props.kittySize !== undefined && props.kittySize !== null
                    ? props.kittySize
                    : "", onChange: handleChange },
                React.createElement("optgroup", { label: "Standard" },
                    React.createElement("option", { value: "" }, "default"),
                    defaultOptions
                        .filter(function (v) { return v < deckLen - props.numPlayers; })
                        .map(function (v) { return (React.createElement("option", { value: v, key: v },
                        v,
                        " card",
                        v === 1 ? "" : "s")); })),
                React.createElement("optgroup", { label: "Requires removing cards from the deck" }, options.map(function (v) { return (React.createElement("option", { value: v, key: v },
                    v,
                    " card",
                    v === 1 ? "" : "s")); }))))));
};
export default KittySizeSelector;

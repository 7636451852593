import * as React from "react";
import { WebsocketContext } from "./WebsocketProvider";
var ResetButton = function (props) {
    var _a, _b, _c;
    var send = React.useContext(WebsocketContext).send;
    var requester = null;
    if ("Draw" in props.state) {
        var state_1 = props.state.Draw;
        requester = (_a = state_1.propagated.players.find(function (player) { return player.id === state_1.player_requested_reset; })) === null || _a === void 0 ? void 0 : _a.name;
    }
    else if ("Exchange" in props.state) {
        var state_2 = props.state.Exchange;
        requester = (_b = state_2.propagated.players.find(function (player) { return player.id === state_2.player_requested_reset; })) === null || _b === void 0 ? void 0 : _b.name;
    }
    else if ("Play" in props.state) {
        var state_3 = props.state.Play;
        requester = (_c = state_3.propagated.players.find(function (player) { return player.id === state_3.player_requested_reset; })) === null || _c === void 0 ? void 0 : _c.name;
    }
    if (requester == null) {
        return (React.createElement("div", { className: "reset-block" },
            React.createElement("a", { href: window.location.href, onClick: function (evt) {
                    evt.preventDefault();
                    send({ Action: "ResetGame" });
                }, title: "Request to return to the game settings screen and re-deal all cards" }, "Reset game")));
    }
    else if (requester === props.name) {
        return (React.createElement("div", { className: "reset-block" },
            React.createElement("p", null, "Waiting for confirmation..."),
            React.createElement("a", { href: window.location.href, onClick: function (evt) {
                    evt.preventDefault();
                    send({ Action: "CancelResetGame" });
                }, title: "Continue playing the game" }, "Cancel")));
    }
    else {
        return (React.createElement("div", { className: "reset-block" },
            React.createElement("p", null,
                requester,
                " wants to reset the game"),
            React.createElement("a", { href: window.location.href, onClick: function (evt) {
                    send({ Action: "ResetGame" });
                }, title: "Return to the game settings screen and re-deal all cards", style: {
                    marginRight: "8px",
                } }, "Accept"),
            React.createElement("a", { href: window.location.href, onClick: function (evt) {
                    evt.preventDefault();
                    send({ Action: "CancelResetGame" });
                }, title: "Continue playing the game" }, "Cancel")));
    }
};
export default ResetButton;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import styled from "styled-components";
import { cardToUnicodeSuit, unicodeToCard, } from "./util/cardHelpers";
import { SettingsContext } from "./AppStateProvider";
var InlineCardBase = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-left: 0.1em;\n  padding-right: 0.1em;\n"], ["\n  padding-left: 0.1em;\n  padding-right: 0.1em;\n"])));
function Suit(className) {
    var component = function (props) {
        var settings = React.useContext(SettingsContext);
        return (React.createElement(InlineCardBase, __assign({ className: className }, props, { style: {
                color: settings.suitColorOverrides[className],
            } })));
    };
    component.displayName = "Suit";
    return component;
}
var Diamonds = Suit("♢");
var Hearts = Suit("♡");
var Spades = Suit("♤");
var Clubs = Suit("♧");
var LittleJoker = Suit("🃟");
var BigJoker = Suit("🃏");
var Unknown = Suit("🂠");
var suitComponent = function (suitCard) {
    switch (suitCard.suit) {
        case "diamonds":
            return Diamonds;
        case "hearts":
            return Hearts;
        case "clubs":
            return Clubs;
        case "spades":
            return Spades;
    }
};
var InlineCard = function (props) {
    var card = unicodeToCard(props.card);
    switch (card.type) {
        case "unknown":
            return React.createElement(Unknown, null, "\uD83C\uDCA0");
        case "big_joker":
            return React.createElement(BigJoker, null, "HJ");
        case "little_joker":
            return React.createElement(LittleJoker, null, "LJ");
        case "suit_card":
            // eslint-disable-next-line no-case-declarations
            var Component = suitComponent(card);
            return (React.createElement(Component, null,
                card.rank,
                cardToUnicodeSuit(card)));
    }
};
export default InlineCard;
var templateObject_1;

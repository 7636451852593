var mapValues = function (object, mapper) {
    var result = {};
    Object.keys(object).forEach(function (k) {
        result[k] = mapper(object[k]);
    });
    return result;
};
var filter = function (object, predicate) {
    var result = {};
    Object.keys(object).forEach(function (key) {
        var value = object[key];
        if (predicate(key, value)) {
            result[key] = value;
        }
    });
    return result;
};
export default {
    mapValues: mapValues,
    filter: filter,
};

import * as React from "react";
import { AppStateContext } from "./AppStateProvider";
import Timeout from "./Timeout";
var Errors = function (props) {
    var updateState = React.useContext(AppStateContext).updateState;
    return (React.createElement("div", { className: "errors", onClick: function () { return updateState({ errors: [] }); } },
        React.createElement(Timeout, { timeout: 5000, callback: function () { return updateState({ errors: [] }); } }),
        props.errors.map(function (err, idx) { return (React.createElement("p", { key: idx },
            React.createElement("code", null, err))); })));
};
export default Errors;

import * as React from "react";
import { TimerContext } from "./TimerProvider";
var Timeout = function (props) {
    var _a = React.useContext(TimerContext), setTimeout = _a.setTimeout, clearTimeout = _a.clearTimeout;
    React.useEffect(function () {
        var timeout = setTimeout(props.callback, props.timeout);
        return function () { return clearTimeout(timeout); };
    });
    return null;
};
export default Timeout;

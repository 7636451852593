var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
var Row = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: table-row;\n  line-height: 23px;\n"], ["\n  display: table-row;\n  line-height: 23px;\n"])));
var LabelCell = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: table-cell;\n  padding-right: 2em;\n  font-weight: bold;\n  width: 200px;\n"], ["\n  display: table-cell;\n  padding-right: 2em;\n  font-weight: bold;\n  width: 200px;\n"])));
var Cell = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: table-cell;\n"], ["\n  display: table-cell;\n"])));
var PublicRoomRow = function (_a) {
    var roomName = _a.roomName, numPlayers = _a.numPlayers, setRoomName = _a.setRoomName;
    return (React.createElement(Row, null,
        React.createElement(Cell, null,
            React.createElement("button", { onClick: function (e) { return setRoomName(roomName, e); }, className: "normal" }, roomName)),
        React.createElement(Cell, null, numPlayers)));
};
var PublicRoomsPane = function (props) {
    var _a = useState([]), publicRooms = _a[0], setPublicRooms = _a[1];
    useEffect(function () {
        loadPublicRooms();
    }, []);
    var loadPublicRooms = function () {
        try {
            var fetchAsync = function () { return __awaiter(void 0, void 0, void 0, function () {
                var fetchResult, resultJSON;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch("public_games.json")];
                        case 1:
                            fetchResult = _a.sent();
                            return [4 /*yield*/, fetchResult.json()];
                        case 2:
                            resultJSON = _a.sent();
                            setPublicRooms(resultJSON);
                            return [2 /*return*/];
                    }
                });
            }); };
            fetchAsync().catch(function (e) {
                console.error(e);
            });
        }
        catch (err) {
            console.log(err);
        }
    };
    return (React.createElement("div", { className: "" },
        React.createElement("h3", null, "Public Rooms"),
        React.createElement("div", null,
            React.createElement("p", null, "The games listed below are open to the public. Join them to find new friends to play with!")),
        React.createElement("div", { style: { display: "table", borderSpacing: 10 } },
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "Room Name"),
                React.createElement(LabelCell, null, "Players"),
                React.createElement(LabelCell, null,
                    React.createElement("button", { onClick: loadPublicRooms, className: "normal" }, "Refresh"))),
            publicRooms.length === 0 && React.createElement(Cell, null, "No public rooms available"),
            publicRooms.map(function (roomInfo) {
                return (React.createElement(PublicRoomRow, { key: roomInfo.name, roomName: roomInfo.name, numPlayers: roomInfo.num_players, setRoomName: props.setRoomName }));
            }))));
};
export default PublicRoomsPane;
var templateObject_1, templateObject_2, templateObject_3;

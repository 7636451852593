import ObjectUtils from "./util/object";
export var noPersistence = function (loadDefault) {
    return {
        loadDefault: loadDefault,
        persist: function () { },
    };
};
export var combineState = function (object) {
    return {
        loadDefault: function () {
            return ObjectUtils.mapValues(object, function (p) { return p.loadDefault(); });
        },
        persist: function (before, after) {
            Object.keys(after).forEach(function (k) {
                if (before[k] !== after[k]) {
                    object[k].persist(before[k], after[k]);
                }
            });
        },
    };
};

import * as React from "react";
import { WebsocketContext } from "./WebsocketProvider";
var BeepButton = function () {
    var send = React.useContext(WebsocketContext).send;
    return (React.createElement("button", { className: "big", onClick: function () {
            return confirm("Do you really want to send a beep to the current player?") &&
                send("Beep");
        } }, "\uD83D\uDECE\uFE0F"));
};
export default BeepButton;

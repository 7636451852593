var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* tslint:disable:max-classes-per-file variable-name forin */
import * as React from "react";
import BeepButton from "./BeepButton";
import BidArea from "./BidArea";
import Trump from "./Trump";
import FriendSelect from "./FriendSelect";
import InlineCard from "./InlineCard";
import Card from "./Card";
import Header from "./Header";
import Friends from "./Friends";
import Players from "./Players";
import LabeledPlay from "./LabeledPlay";
import Cards from "./Cards";
var Exchange = /** @class */ (function (_super) {
    __extends(Exchange, _super);
    function Exchange(props) {
        var _this = _super.call(this, props) || this;
        _this.moveCardToKitty = _this.moveCardToKitty.bind(_this);
        _this.moveCardToHand = _this.moveCardToHand.bind(_this);
        _this.startGame = _this.startGame.bind(_this);
        _this.pickUpKitty = _this.pickUpKitty.bind(_this);
        _this.putDownKitty = _this.putDownKitty.bind(_this);
        _this.pickFriends = _this.pickFriends.bind(_this);
        _this.state = {
            friends: [],
        };
        _this.fixFriends = _this.fixFriends.bind(_this);
        return _this;
    }
    Exchange.prototype.fixFriends = function () {
        if (this.props.state.game_mode !== "Tractor") {
            var gameMode = this.props.state.game_mode.FindingFriends;
            var numFriends = gameMode.num_friends;
            var propFriends = gameMode.friends;
            if (numFriends !== this.state.friends.length) {
                if (propFriends.length !== numFriends) {
                    var friends = __spreadArrays(this.state.friends);
                    while (friends.length < numFriends) {
                        friends.push({
                            card: "",
                            skip: 0,
                            initial_skip: 0,
                            player_id: null,
                        });
                    }
                    while (friends.length > numFriends) {
                        friends.pop();
                    }
                    this.setState({ friends: friends });
                }
                else {
                    this.setState({ friends: propFriends });
                }
            }
        }
        else {
            if (this.state.friends.length !== 0) {
                this.setState({ friends: [] });
            }
        }
    };
    Exchange.prototype.componentDidMount = function () {
        this.fixFriends();
    };
    Exchange.prototype.componentDidUpdate = function () {
        this.fixFriends();
    };
    Exchange.prototype.moveCardToKitty = function (card) {
        window.send({ Action: { MoveCardToKitty: card } });
    };
    Exchange.prototype.moveCardToHand = function (card) {
        window.send({ Action: { MoveCardToHand: card } });
    };
    Exchange.prototype.startGame = function (evt) {
        evt.preventDefault();
        window.send({ Action: "BeginPlay" });
    };
    Exchange.prototype.pickUpKitty = function (evt) {
        evt.preventDefault();
        window.send({ Action: "PickUpKitty" });
    };
    Exchange.prototype.putDownKitty = function (evt) {
        evt.preventDefault();
        window.send({ Action: "PutDownKitty" });
    };
    Exchange.prototype.pickFriends = function (evt) {
        evt.preventDefault();
        if (this.props.state.game_mode !== "Tractor" &&
            this.props.state.game_mode.FindingFriends.num_friends ===
                this.state.friends.length) {
            window.send({
                Action: {
                    SetFriends: this.state.friends,
                },
            });
        }
        else {
            this.fixFriends();
        }
    };
    Exchange.prototype.render = function () {
        var _this = this;
        var exchanger = this.props.state.exchanger === null
            ? this.props.state.landlord
            : this.props.state.exchanger;
        var landlordIdx = -1;
        var exchangerIdx = -1;
        var playerId = -1;
        this.props.state.propagated.players.forEach(function (player, idx) {
            if (player.id === _this.props.state.landlord) {
                landlordIdx = idx;
            }
            if (player.id === exchanger) {
                exchangerIdx = idx;
            }
            if (player.name === _this.props.name) {
                playerId = player.id;
            }
        });
        var isLandlord = this.props.state.propagated.players[landlordIdx].name === this.props.name;
        var isExchanger = this.props.state.propagated.players[exchangerIdx].name ===
            this.props.name;
        var kittyTheftEnabled = this.props.state.propagated.kitty_theft_policy === "AllowKittyTheft";
        var nextPlayer = kittyTheftEnabled &&
            !this.props.state.finalized &&
            this.props.state.exchanger !== null
            ? this.props.state.exchanger
            : this.props.state.landlord;
        var exchangeUI = isExchanger && !this.props.state.finalized ? (React.createElement(React.Fragment, null,
            React.createElement("h2", null, "Your hand"),
            React.createElement(Cards, { hands: this.props.state.hands, playerId: playerId, onCardClick: function (c) { return _this.moveCardToKitty(c); }, trump: this.props.state.trump }),
            React.createElement("h2", null,
                "Discarded cards ",
                this.props.state.kitty.length,
                " /",
                " ",
                this.props.state.kitty_size),
            React.createElement("div", { className: "kitty" }, this.props.state.kitty.map(function (c, idx) { return (React.createElement(Card, { trump: _this.props.state.trump, key: idx, onClick: function () { return _this.moveCardToHand(c); }, collapseRight: idx !== _this.props.state.kitty.length - 1, card: c })); })),
            kittyTheftEnabled ? (React.createElement("button", { onClick: this.putDownKitty, disabled: this.props.state.kitty.length !== this.props.state.kitty_size, className: "big" }, "Finalize exchanged cards")) : null)) : null;
        var lastBid = this.props.state.bids[this.props.state.bids.length - 1];
        var startGame = (React.createElement("button", { onClick: this.startGame, disabled: this.props.state.kitty.length !== this.props.state.kitty_size ||
                (kittyTheftEnabled &&
                    !this.props.state.finalized &&
                    this.props.state.autobid === null), className: "big" }, "Start game"));
        var bidUI = kittyTheftEnabled &&
            this.props.state.finalized &&
            this.props.state.autobid === null &&
            (!isExchanger || lastBid.epoch + 1 !== this.props.state.epoch) ? (React.createElement(React.Fragment, null,
            React.createElement(BidArea, { bids: this.props.state.bids, autobid: this.props.state.autobid, hands: this.props.state.hands, epoch: this.props.state.epoch, name: this.props.name, landlord: this.props.state.propagated.landlord, players: this.props.state.propagated.players, bidPolicy: this.props.state.propagated.bid_policy, bidReinforcementPolicy: this.props.state.propagated.bid_reinforcement_policy, jokerBidPolicy: this.props.state.propagated.joker_bid_policy, numDecks: this.props.state.num_decks, header: React.createElement("h2", null,
                    "Bids (round ",
                    this.props.state.epoch + 1,
                    " of bidding)"), suffixButtons: React.createElement(React.Fragment, null,
                    React.createElement("button", { onClick: this.pickUpKitty, disabled: lastBid.id !== playerId ||
                            lastBid.epoch !== this.props.state.epoch, className: "big" }, "Pick up cards from the bottom"),
                    isLandlord ? startGame : null), bidTakeBacksEnabled: this.props.state.propagated.bid_takeback_policy ===
                    "AllowBidTakeback" }),
            React.createElement(LabeledPlay, { className: "kitty", trump: this.props.state.trump, cards: this.props.state.kitty, label: "\u5E95\u724C" }))) : null;
        var friendUI = this.props.state.game_mode !== "Tractor" && isLandlord ? (React.createElement("div", null,
            React.createElement(Friends, { gameMode: this.props.state.game_mode, showPlayed: false }),
            this.state.friends.map(function (friend, idx) {
                var onChange = function (x) {
                    var newFriends = __spreadArrays(_this.state.friends);
                    newFriends[idx] = x;
                    _this.setState({ friends: newFriends });
                    _this.fixFriends();
                };
                return (React.createElement(FriendSelect, { onChange: onChange, key: idx, friend: friend, trump: _this.props.state.trump, friend_selection_policy: _this.props.state.propagated.friend_selection_policy, num_decks: _this.props.state.num_decks }));
            }),
            React.createElement("button", { onClick: this.pickFriends, className: "big" }, "Pick friends"))) : null;
        return (React.createElement("div", null,
            React.createElement(Header, { gameMode: this.props.state.game_mode, chatLink: this.props.state.propagated.chat_link }),
            React.createElement(Players, { players: this.props.state.propagated.players, observers: this.props.state.propagated.observers, landlord: this.props.state.landlord, next: this.props.state.landlord, name: this.props.name }),
            React.createElement(Trump, { trump: this.props.state.trump }),
            this.props.state.removed_cards.length > 0 ? (React.createElement("p", null,
                "Note:",
                " ",
                this.props.state.removed_cards.map(function (c) { return (React.createElement(InlineCard, { key: c, card: c })); }),
                " ",
                "have been removed from the deck")) : null,
            friendUI,
            exchangeUI,
            exchangeUI === null && bidUI === null && playerId >= 0 ? (React.createElement(React.Fragment, null,
                React.createElement(Cards, { hands: this.props.state.hands, playerId: playerId, trump: this.props.state.trump }),
                React.createElement("p", null, "Waiting..."))) : null,
            playerId !== nextPlayer && React.createElement(BeepButton, null),
            isLandlord && bidUI === null ? startGame : null,
            bidUI));
    };
    return Exchange;
}(React.Component));
export default Exchange;

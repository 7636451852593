import * as React from "react";
import ArrayUtils from "./util/array";
var NumDecksSelector = function (props) {
    var handleChange = function (e) {
        var newNumDecks = e.target.value === "" ? null : parseInt(e.target.value, 10);
        props.onChange(newNumDecks);
    };
    return (React.createElement("div", { className: "num-decks-picker" },
        React.createElement("label", null,
            "Number of decks:",
            " ",
            React.createElement("select", { value: props.numDecks === null ? "" : props.numDecks, onChange: handleChange },
                React.createElement("option", { value: "" }, "default"),
                ArrayUtils.range(props.numPlayers, function (idx) {
                    var val = idx + 1;
                    return (React.createElement("option", { value: val, key: idx }, val));
                })))));
};
export default NumDecksSelector;

import * as React from "react";
import InlineCard from "./InlineCard";
var Friends = function (props) {
    var gameMode = props.gameMode;
    if (gameMode !== "Tractor") {
        return (React.createElement("div", { className: "pending-friends" }, gameMode.FindingFriends.friends.map(function (friend, idx) {
            if (friend.player_id !== null) {
                return null;
            }
            if (friend.card === null ||
                friend.card === undefined ||
                friend.card.length === 0) {
                return null;
            }
            return (React.createElement("p", { key: idx },
                "The person to play the ",
                nth(friend.initial_skip + 1),
                " ",
                React.createElement(InlineCard, { card: friend.card }),
                " is a friend.",
                " ",
                props.showPlayed
                    ? friend.initial_skip - friend.skip + " played in previous tricks."
                    : ""));
        })));
    }
    else {
        return null;
    }
};
function nth(n) {
    var suffix = ["st", "nd", "rd"][(((((n < 0 ? -n : n) + 90) % 100) - 10) % 10) - 1];
    return "" + n + (suffix !== undefined ? suffix : "th");
}
export default Friends;

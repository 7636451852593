var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import styled from "styled-components";
var Button = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  outline: none;\n  padding: 0;\n  margin: 0;\n  border: 0;\n  background-color: transparent;\n  transition: opacity 100ms ease-in-out, color 150ms ease-in-out,\n    transform 100ms ease-in-out;\n  color: #111;\n  &:hover {\n    color: #666;\n  }\n"], ["\n  outline: none;\n  padding: 0;\n  margin: 0;\n  border: 0;\n  background-color: transparent;\n  transition: opacity 100ms ease-in-out, color 150ms ease-in-out,\n    transform 100ms ease-in-out;\n  color: #111;\n  &:hover {\n    color: #666;\n  }\n"])));
var IconButton = function (props) {
    return React.createElement(Button, __assign({ className: "icon-button" }, props));
};
export default IconButton;
var templateObject_1;

import * as React from "react";
import classNames from "classnames";
import { MovePlayerLeft, MovePlayerRight } from "./MovePlayerButton";
import { WebsocketContext } from "./WebsocketProvider";
var Players = function (props) {
    var players = props.players, observers = props.observers, landlord = props.landlord, 
    // eslint-disable-next-line @typescript-eslint/naming-convention
    landlords_team = props.landlords_team, movable = props.movable, next = props.next, name = props.name;
    var send = React.useContext(WebsocketContext).send;
    var makeDescriptor = function (p) {
        if (p.metalevel <= 1) {
            return [p.name + " (rank " + p.level + ")"];
        }
        else {
            return [
                p.name + " (rank " + p.level,
                React.createElement("sup", { key: "meta-" + p.id }, p.metalevel),
                ")",
            ];
        }
    };
    return (React.createElement("table", { className: "players" },
        React.createElement("tbody", null,
            React.createElement("tr", null,
                players.map(function (player) {
                    var className = classNames("player", {
                        landlord: player.id === landlord || (landlords_team === null || landlords_team === void 0 ? void 0 : landlords_team.includes(player.id)),
                        movable: movable,
                        next: player.id === next,
                    });
                    var descriptor = makeDescriptor(player);
                    if (player.id === landlord) {
                        descriptor.push(" (当庄)");
                    }
                    if (player.name === name) {
                        descriptor.push(" (You!)");
                    }
                    return (React.createElement("td", { key: player.id, className: className },
                        descriptor,
                        movable && (React.createElement("span", { style: {
                                display: "block",
                                marginTop: "6px",
                                textAlign: "center",
                                width: "100%",
                            } },
                            React.createElement(MovePlayerLeft, { players: players, player: player }),
                            React.createElement("span", { style: { cursor: "pointer" }, onClick: function (_) {
                                    send({ Action: { MakeObserver: player.id } });
                                } }, "\u2714\uFE0F"),
                            React.createElement(MovePlayerRight, { players: players, player: player })))));
                }),
                observers.map(function (player) {
                    var className = classNames("player observer", { movable: movable });
                    var descriptor = makeDescriptor(player);
                    if (player.name === name) {
                        descriptor.push(" (You!)");
                    }
                    return (React.createElement("td", { key: player.id, className: className },
                        React.createElement("span", { style: { textDecoration: "line-through" } }, descriptor),
                        movable && (React.createElement("span", { style: {
                                display: "block",
                                marginTop: "6px",
                                textAlign: "center",
                                width: "100%",
                            } },
                            React.createElement("span", { style: { cursor: "pointer" }, onClick: function (_) {
                                    send({ Action: { MakePlayer: player.id } });
                                } }, "\uD83D\uDCA4")))));
                })))));
};
export default Players;

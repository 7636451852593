import * as React from "react";
var GameModeE = function (props) {
    var rules = (React.createElement("a", { href: "rules.html", target: "_blank" }, "rules"));
    if (props.gameMode === "Tractor") {
        return (React.createElement("span", null,
            "\u5347\u7EA7 / ",
            React.createElement("span", { className: "red" }, "Tractor"),
            " (",
            rules,
            ")"));
    }
    else {
        return (React.createElement("span", null,
            "\u627E\u670B\u53CB / ",
            React.createElement("span", { className: "red" }, "Finding Friends"),
            " (",
            rules,
            ")"));
    }
};
export default GameModeE;

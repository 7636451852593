var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { WebsocketContext } from "./WebsocketProvider";
import { WasmContext } from "./WasmContext";
export var GameScoringSettings = function (props) {
    var send = React.useContext(WebsocketContext).send;
    var explainScoring = React.useContext(WasmContext).explainScoring;
    var _a = React.useState(null), highlighted = _a[0], setHighlighted = _a[1];
    var updateSettings = function (updates) {
        send({
            Action: {
                SetGameScoringParameters: __assign(__assign({}, props.params), updates),
            },
        });
    };
    var bonusEnabled = props.params.bonus_level_policy === "BonusLevelForSmallerLandlordTeam";
    var _b = explainScoring({
        params: props.params,
        smaller_landlord_team_size: false,
        decks: props.decks,
    }), scoreTransitions = _b.results, stepSize = _b.step_size, totalPoints = _b.total_points;
    var bonusScoreTransitions = bonusEnabled
        ? explainScoring({
            params: props.params,
            smaller_landlord_team_size: true,
            decks: props.decks,
        }).results
        : scoreTransitions;
    var scoreSegments = [];
    var maxPts = 0;
    var maxLandlordDelta = 0;
    var maxNonLandlordDelta = 0;
    for (var i = 1; i < scoreTransitions.length; i++) {
        var span = Math.max(scoreTransitions[i].point_threshold -
            scoreTransitions[i - 1].point_threshold, 10);
        var segment = scoreTransitions[i - 1];
        maxLandlordDelta = Math.max(segment.results.landlord_delta, maxLandlordDelta);
        maxNonLandlordDelta = Math.max(segment.results.non_landlord_delta, maxNonLandlordDelta);
        scoreSegments.push({
            span: span,
            segment: segment,
            bonusSegment: bonusScoreTransitions[i - 1].results.landlord_bonus
                ? bonusScoreTransitions[i - 1]
                : null,
        });
        maxPts += span;
    }
    var last = scoreTransitions.length - 1;
    scoreSegments.push({
        span: 5 * props.decks.length,
        segment: scoreTransitions[last],
        bonusSegment: bonusScoreTransitions[last].results.landlord_bonus
            ? bonusScoreTransitions[last]
            : null,
    });
    maxPts += 5 * props.decks.length;
    maxNonLandlordDelta = Math.max(scoreTransitions[last].results.non_landlord_delta, maxNonLandlordDelta);
    maxLandlordDelta = Math.max(scoreTransitions[last].results.landlord_delta, maxLandlordDelta);
    var text = function (idx) {
        var txt = "Attacking team wins, but doesn't level up.";
        var segment = scoreSegments[idx];
        if (segment.segment.results.landlord_won) {
            txt = "Defending team wins, and goes up " + segment.segment.results.landlord_delta + " level" + (segment.segment.results.landlord_delta === 1 ? "" : "s") + ".";
            if (segment.bonusSegment !== null) {
                txt += " If the team is unexpectedly small, they go up " + segment.bonusSegment.results.landlord_delta + " level" + (segment.bonusSegment.results.landlord_delta === 1 ? "" : "s") + ".";
            }
        }
        else if (segment.segment.results.non_landlord_delta > 0) {
            txt = "Attacking team wins, and goes up " + segment.segment.results.non_landlord_delta + " level" + (segment.segment.results.non_landlord_delta === 1 ? "" : "s") + ".";
        }
        return React.createElement(React.Fragment, null, txt);
    };
    var validStepSizes = [];
    for (var curStepSize = 0; curStepSize <= totalPoints / 3; curStepSize += 5 * props.decks.length) {
        if (curStepSize === 0) {
            continue;
        }
        if (totalPoints % curStepSize === 0) {
            validStepSizes.push("" + curStepSize);
        }
    }
    var maxSteps = Math.floor(totalPoints / stepSize);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement("div", { style: { width: "95%", padding: "5px 0 5px 0" } },
                scoreSegments.map(function (segment, idx) {
                    var frac = segment.span / maxPts;
                    var bg = "rgb(255, 255, 0)";
                    if (segment.segment.results.landlord_won) {
                        var f = segment.segment.results.landlord_delta / maxLandlordDelta;
                        bg = "rgba(0, 255, 0, " + f + ")";
                    }
                    else if (segment.segment.results.non_landlord_delta > 0) {
                        var f = segment.segment.results.non_landlord_delta /
                            maxNonLandlordDelta;
                        bg = "rgba(255, 0, 0, " + f + ")";
                    }
                    return (React.createElement("div", { key: idx, onMouseEnter: function (_) {
                            setHighlighted(idx);
                        }, onMouseLeave: function (_) {
                            setHighlighted(null);
                        }, style: {
                            width: 100 * frac + "%",
                            background: bg,
                            padding: "5px 0 5px 0",
                            display: "inline-block",
                            cursor: "pointer",
                        } }, segment.segment.point_threshold));
                }),
                highlighted !== null ? (React.createElement("p", null,
                    " ",
                    text(highlighted))) : (React.createElement("p", null, "Hover over the scores above for more details."))),
            React.createElement("div", null,
                React.createElement("label", null,
                    "Step size: ",
                    stepSize,
                    " points")),
            React.createElement("div", null,
                React.createElement("label", null, "Base step size: "),
                React.createElement("select", { value: "" + props.params.step_size_per_deck * props.decks.length, onChange: function (evt) {
                        evt.preventDefault();
                        var perDeck = parseInt(evt.target.value, 10) / props.decks.length;
                        updateSettings({
                            step_size_per_deck: perDeck,
                        });
                    } }, validStepSizes.map(function (ss, idx) { return (React.createElement("option", { key: idx }, ss)); })),
                " ",
                "(default: ",
                20 * props.decks.length,
                ")"),
            React.createElement("div", null,
                React.createElement("label", null,
                    "Adjustment to step size for ",
                    props.decks.length,
                    " decks:",
                    " "),
                React.createElement("select", { value: props.params.step_adjustments[props.decks.length] !== undefined
                        ? props.params.step_adjustments[props.decks.length]
                        : "none", onChange: function (evt) {
                        var _a;
                        evt.preventDefault();
                        if (evt.target.value === "none") {
                            var _b = props.params.step_adjustments, _c = props.decks.length, _1 = _b[_c], adjustments = __rest(_b, [typeof _c === "symbol" ? _c : _c + ""]);
                            updateSettings({ step_adjustments: adjustments });
                        }
                        else {
                            var adjustments = __assign(__assign({}, props.params.step_adjustments), (_a = {}, _a[props.decks.length] = parseInt(evt.target.value, 10), _a));
                            updateSettings({ step_adjustments: adjustments });
                        }
                    } },
                    React.createElement("option", { key: "none" }, "none"),
                    Array((props.params.step_size_per_deck * props.decks.length) / 5)
                        .fill(undefined)
                        .map(function (_, idx) { return (React.createElement("option", { key: idx }, (idx + 1) * 5)); })),
                " ",
                "(default: none)"),
            React.createElement("div", null,
                React.createElement("label", null, "Number of steps where nobody gains a level: "),
                React.createElement("select", { value: "" + props.params.deadzone_size, onChange: function (evt) {
                        evt.preventDefault();
                        var deadzoneSize = parseInt(evt.target.value, 10);
                        updateSettings({
                            deadzone_size: deadzoneSize,
                        });
                    } }, Array(Math.max(maxSteps, props.params.deadzone_size))
                    .fill(undefined)
                    .map(function (_, idx) { return (React.createElement("option", { key: idx }, idx)); })),
                " ",
                "(default: 1)"),
            React.createElement("div", null,
                React.createElement("label", null, "Number of steps for the attacking team to win: "),
                React.createElement("select", { value: "" + props.params.num_steps_to_non_landlord_turnover, onChange: function (evt) {
                        evt.preventDefault();
                        var steps = parseInt(evt.target.value, 10);
                        updateSettings({
                            num_steps_to_non_landlord_turnover: steps,
                        });
                    } }, Array(Math.max(maxSteps, props.params.num_steps_to_non_landlord_turnover))
                    .fill(undefined)
                    .map(function (_, idx) { return (React.createElement("option", { key: idx + 1 }, idx + 1)); })),
                " ",
                "(default: 2)"),
            React.createElement("div", null,
                React.createElement("label", null, "Grant a bonus level for unexpectedly small team"),
                " ",
                React.createElement("input", { id: "small-team-bonus", type: "checkbox", onChange: function (evt) {
                        evt.preventDefault();
                        updateSettings({
                            bonus_level_policy: evt.target.checked
                                ? "BonusLevelForSmallerLandlordTeam"
                                : "NoBonusLevel",
                        });
                    }, checked: bonusEnabled })))));
};

import * as React from "react";
import beep from "./beep";
import { TimerContext } from "./TimerProvider";
var defaultBeeper = function () { return beep(3, 440, 200); };
var Beeper = function (_a) {
    var _b = _a.beeper, beeper = _b === void 0 ? defaultBeeper : _b, _c = _a.interval, interval = _c === void 0 ? 5000 : _c;
    var _d = React.useContext(TimerContext), setInterval = _d.setInterval, clearInterval = _d.clearInterval;
    React.useEffect(function () {
        beeper();
        var timer = setInterval(beeper, interval);
        return function () { return clearInterval(timer); };
    }, []);
    return null;
};
export default Beeper;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:max-classes-per-file variable-name forin */
import * as React from "react";
import Header from "./Header";
import Players from "./Players";
import LabeledPlay from "./LabeledPlay";
import BeepButton from "./BeepButton";
import BidArea from "./BidArea";
import InlineCard from "./InlineCard";
var Draw = /** @class */ (function (_super) {
    __extends(Draw, _super);
    function Draw(props) {
        var _this = _super.call(this, props) || this;
        _this.could_draw = false;
        _this.timeout = null;
        _this.drawCardAudio = null;
        _this.state = {
            autodraw: true,
        };
        _this.drawCard = _this.drawCard.bind(_this);
        _this.pickUpKitty = _this.pickUpKitty.bind(_this);
        _this.revealCard = _this.revealCard.bind(_this);
        _this.onAutodrawClicked = _this.onAutodrawClicked.bind(_this);
        return _this;
    }
    Draw.prototype.drawCard = function () {
        var canDraw = this.props.state.propagated.players[this.props.state.position].name ===
            this.props.name;
        if (this.timeout !== null) {
            this.props.clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (canDraw) {
            if (this.props.playDrawCardSound) {
                if (this.drawCardAudio === null) {
                    this.drawCardAudio = new Audio("434472_dersuperanton_taking-card.mp3");
                }
                // eslint-disable-next-line
                this.drawCardAudio.play();
            }
            window.send({ Action: "DrawCard" });
        }
    };
    Draw.prototype.pickUpKitty = function (evt) {
        evt.preventDefault();
        window.send({ Action: "PickUpKitty" });
    };
    Draw.prototype.revealCard = function (evt) {
        evt.preventDefault();
        window.send({ Action: "RevealCard" });
    };
    Draw.prototype.onAutodrawClicked = function (evt) {
        this.setState({
            autodraw: evt.target.checked,
        });
        if (evt.target.checked) {
            this.drawCard();
        }
        else {
            if (this.timeout !== null) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
        }
    };
    Draw.prototype.render = function () {
        var _this = this;
        var canDraw = this.props.state.propagated.players[this.props.state.position].name ===
            this.props.name && this.props.state.deck.length > 0;
        if (canDraw &&
            !this.could_draw &&
            this.timeout === null &&
            this.state.autodraw) {
            this.timeout = this.props.setTimeout(function () {
                _this.drawCard();
            }, this.props.autodrawSpeedMs !== null ? this.props.autodrawSpeedMs : 250);
        }
        this.could_draw = canDraw;
        var next = this.props.state.propagated.players[this.props.state.position].id;
        if (this.props.state.deck.length === 0 &&
            this.props.state.bids.length > 0) {
            next = this.props.state.bids[this.props.state.bids.length - 1].id;
        }
        var players = {};
        var playerId = -1;
        this.props.state.propagated.players.forEach(function (p) {
            players[p.id] = p;
            if (p.name === _this.props.name) {
                playerId = p.id;
            }
        });
        var landlord = this.props.state.propagated.landlord;
        var trump;
        if (landlord !== null &&
            landlord !== undefined &&
            players[landlord] !== undefined) {
            trump = {
                NoTrump: {
                    number: players[landlord].level !== "NT" &&
                        players[landlord].level !== undefined &&
                        players[landlord].level !== null
                        ? players[landlord].level
                        : null,
                },
            };
        }
        return (React.createElement("div", null,
            React.createElement(Header, { gameMode: this.props.state.game_mode, chatLink: this.props.state.propagated.chat_link }),
            React.createElement(Players, { players: this.props.state.propagated.players, observers: this.props.state.propagated.observers, landlord: landlord, next: next, name: this.props.name }),
            React.createElement(BidArea, { bids: this.props.state.bids, autobid: this.props.state.autobid, hands: this.props.state.hands, epoch: 0, name: this.props.name, trump: trump, landlord: landlord, players: this.props.state.propagated.players, bidPolicy: this.props.state.propagated.bid_policy, bidReinforcementPolicy: this.props.state.propagated.bid_reinforcement_policy, jokerBidPolicy: this.props.state.propagated.joker_bid_policy, numDecks: this.props.state.num_decks, header: React.createElement(React.Fragment, null,
                    React.createElement("h2", null,
                        "Bids (",
                        this.props.state.deck.length,
                        " cards remaining in the deck)"),
                    this.props.state.removed_cards.length > 0 ? (React.createElement("p", null,
                        "Note:",
                        " ",
                        this.props.state.removed_cards.map(function (c) { return (React.createElement(InlineCard, { key: c, card: c })); }),
                        " ",
                        "have been removed from the deck")) : null), prefixButtons: React.createElement(React.Fragment, null,
                    React.createElement("button", { onClick: function (evt) {
                            evt.preventDefault();
                            _this.drawCard();
                        }, disabled: !canDraw, className: "big" }, "Draw card"),
                    React.createElement("label", null,
                        "auto-draw",
                        React.createElement("input", { type: "checkbox", name: "autodraw", checked: this.state.autodraw, onChange: this.onAutodrawClicked }))), suffixButtons: React.createElement(React.Fragment, null,
                    React.createElement("button", { onClick: this.pickUpKitty, disabled: this.props.state.deck.length > 0 ||
                            (this.props.state.bids.length === 0 &&
                                this.props.state.autobid === null &&
                                !(landlord !== null && players[landlord].level === "NT")) ||
                            (landlord !== null && landlord !== playerId) ||
                            (landlord === null &&
                                ((this.props.state.propagated
                                    .first_landlord_selection_policy === "ByWinningBid" &&
                                    this.props.state.bids[this.props.state.bids.length - 1]
                                        .id !== playerId) ||
                                    (this.props.state.propagated
                                        .first_landlord_selection_policy === "ByFirstBid" &&
                                        this.props.state.bids[0].id !== playerId))), className: "big" }, "Pick up cards from the bottom"),
                    React.createElement("button", { onClick: this.revealCard, disabled: landlord === null ||
                            landlord === undefined ||
                            this.props.state.deck.length > 0 ||
                            this.props.state.bids.length > 0 ||
                            this.props.state.autobid !== null ||
                            this.props.state.revealed_cards >=
                                this.props.state.kitty.length ||
                            (landlord !== null &&
                                landlord !== undefined &&
                                players[landlord].level === "NT"), className: "big" }, "Reveal card from the bottom"),
                    React.createElement(BeepButton, null)), bidTakeBacksEnabled: this.props.state.propagated.bid_takeback_policy ===
                    "AllowBidTakeback" }),
            React.createElement(LabeledPlay, { className: "kitty", cards: this.props.state.kitty, trump: { NoTrump: {} }, label: "\u5E95\u724C" })));
    };
    return Draw;
}(React.Component));
export default Draw;

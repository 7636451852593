var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var sum = function (array) { return array.reduce(function (a, b) { return a + b; }, 0); };
var identity = function (l, r) { return l === r; };
// Subtracts one array from another. Works with arrays with duplicate values,
// and throws an exception if the smaller array is not completely contained in
// the larger one.
var minus = function (large, small, equality) {
    if (equality === void 0) { equality = identity; }
    var result = __spreadArrays(large);
    small.forEach(function (valueToRemove) {
        var index = result.findIndex(function (t) { return equality(t, valueToRemove); });
        if (index >= 0) {
            result.splice(index, 1);
        }
    });
    return result;
};
var mapObject = function (array, mapper) {
    var result = {};
    array.forEach(function (t) {
        var _a = mapper(t), key = _a[0], value = _a[1];
        result[key] = value;
    });
    return result;
};
var range = function (count, fn) {
    return count !== undefined && count >= 0
        ? Array(count)
            .fill(undefined)
            .map(function (_, idx) { return fn(idx); })
        : [];
};
var shuffled = function (array) {
    return array
        .map(function (a) { return ({ sort: Math.random(), value: a }); })
        .sort(function (a, b) { return a.sort - b.sort; })
        .map(function (a) { return a.value; });
};
export default {
    mapObject: mapObject,
    minus: minus,
    range: range,
    sum: sum,
    shuffled: shuffled,
};

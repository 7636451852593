var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
var Row = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: table-row;\n  line-height: 23px;\n"], ["\n  display: table-row;\n  line-height: 23px;\n"])));
var LabelCell = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: table-cell;\n  padding-right: 2em;\n  font-weight: bold;\n"], ["\n  display: table-cell;\n  padding-right: 2em;\n  font-weight: bold;\n"])));
var Cell = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: table-cell;\n"], ["\n  display: table-cell;\n"])));
var percentage = function (numerator, denominator) {
    if (denominator > 0) {
        return ((numerator / denominator) * 100).toFixed(2) + "%";
    }
    return "n/a";
};
var ranksPerGame = function (ranks, numGames) {
    if (numGames > 0) {
        return (ranks / numGames).toFixed(3);
    }
    return "n/a";
};
var GameStatisticsRow = function (_a) {
    var label = _a.label, numPlayed = _a.numPlayed, numWon = _a.numWon;
    return (React.createElement(Row, null,
        React.createElement(LabelCell, null, label),
        React.createElement(Cell, null, numPlayed),
        React.createElement(Cell, null, numWon),
        React.createElement(Cell, null, percentage(numWon, numPlayed))));
};
var GameStatisticsPane = function (props) {
    var gameStatistics = props.gameStatistics;
    var gamesPlayedAsAttacking = gameStatistics.gamesPlayed - gameStatistics.gamesPlayedAsDefending;
    var gamesWonAsAttacking = gameStatistics.gamesWon - gameStatistics.gamesWonAsDefending;
    return (React.createElement("div", { className: "gameStatistics" },
        React.createElement("h3", null, "win statistics"),
        React.createElement("div", { style: { display: "table" } },
            React.createElement(Row, null,
                React.createElement(Cell, null),
                React.createElement(LabelCell, null, "played"),
                React.createElement(LabelCell, null, "won"),
                React.createElement(LabelCell, null, "percentage")),
            React.createElement(GameStatisticsRow, { label: "attacking", numPlayed: gamesPlayedAsAttacking, numWon: gamesWonAsAttacking }),
            React.createElement(GameStatisticsRow, { label: "defending", numPlayed: gameStatistics.gamesPlayedAsDefending, numWon: gameStatistics.gamesWonAsDefending }),
            React.createElement(GameStatisticsRow, { label: "as landlord", numPlayed: gameStatistics.gamesPlayedAsLandlord, numWon: gameStatistics.gamesWonAsLandlord }),
            React.createElement(GameStatisticsRow, { label: "total", numPlayed: gameStatistics.gamesPlayed, numWon: gameStatistics.gamesWon })),
        React.createElement("h3", null, "rank up statistics"),
        React.createElement("div", { style: { display: "table" } },
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "ranks/game"),
                React.createElement(Cell, null, ranksPerGame(gameStatistics.ranksUp, gameStatistics.gamesPlayed))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "ranks/win"),
                React.createElement(Cell, null, ranksPerGame(gameStatistics.ranksUp, gameStatistics.gamesWon))))));
};
export default GameStatisticsPane;
var templateObject_1, templateObject_2, templateObject_3;

import * as React from "react";
import { WebsocketContext } from "./WebsocketProvider";
import ArrayUtils from "./util/array";
export var RandomizePlayersButton = function (props) {
    var players = props.players;
    var send = React.useContext(WebsocketContext).send;
    var randomize = function () {
        send({
            Action: { ReorderPlayers: ArrayUtils.shuffled(players.map(function (p) { return p.id; })) },
        });
    };
    return (React.createElement("button", { className: "big", onClick: randomize }, props.children));
};

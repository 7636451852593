import * as React from "react";
var LandlordSelector = function (props) {
    var handleChange = function (e) {
        if (e.target.value === "") {
            props.onChange(null);
        }
        else {
            props.onChange(parseInt(e.target.value, 10));
        }
    };
    return (React.createElement("div", { className: "landlord-picker" },
        React.createElement("label", null,
            "Current leader:",
            " ",
            React.createElement("select", { value: props.landlordId === null ? "" : props.landlordId, onChange: handleChange },
                React.createElement("option", { value: "" }, "determined by the bid"),
                props.players.map(function (player) { return (React.createElement("option", { value: player.id, key: player.id }, player.name)); })))));
};
export default LandlordSelector;

import memoize from "./memoize";
var getContext = memoize(function () { return new window.AudioContext(); });
var beep = function (vol, freq, duration) {
    if (window.AudioContext !== undefined) {
        var beepContext = getContext();
        var v = beepContext.createOscillator();
        var u = beepContext.createGain();
        v.connect(u);
        v.frequency.value = freq;
        v.type = "square";
        u.connect(beepContext.destination);
        u.gain.value = vol * 0.01;
        v.start(beepContext.currentTime);
        v.stop(beepContext.currentTime + duration * 0.001);
    }
    else {
        alert("Your browser doesn't support the beep feature! Beep!");
    }
};
export default beep;

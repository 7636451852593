var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from "react";
import { WebsocketContext } from "./WebsocketProvider";
function MovePlayerButton(relative, children) {
    var component = function (props) {
        var players = props.players, player = props.player;
        var send = React.useContext(WebsocketContext).send;
        var movePlayer = function () {
            var index = players.findIndex(function (p) { return p === player; });
            var newIndex = (index + relative) % players.length;
            var withoutPlayer = players.filter(function (p) { return p !== player; });
            var newPlayers = __spreadArrays(withoutPlayer.slice(0, newIndex), [
                player
            ], withoutPlayer.slice(newIndex, withoutPlayer.length));
            send({ Action: { ReorderPlayers: newPlayers.map(function (p) { return p.id; }) } });
        };
        return React.createElement("button", { onClick: movePlayer }, children);
    };
    component.displayName = "MovePlayerButton";
    return component;
}
export var MovePlayerLeft = MovePlayerButton(-1, "<");
export var MovePlayerRight = MovePlayerButton(1, ">");

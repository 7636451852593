import { combineState } from "../State";
import { numberLocalStorageState } from "../localStorageState";
var gamesPlayed = numberLocalStorageState("games_played");
var gamesPlayedAsDefending = numberLocalStorageState("games_played_as_defending");
var gamesPlayedAsLandlord = numberLocalStorageState("games_played_as_landlord");
var gamesWon = numberLocalStorageState("games_won");
var gamesWonAsDefending = numberLocalStorageState("games_won_as_defending");
var gamesWonAsLandlord = numberLocalStorageState("games_won_as_landlord");
var ranksUp = numberLocalStorageState("ranks_up");
var gameStatistics = combineState({
    gamesPlayed: gamesPlayed,
    gamesPlayedAsDefending: gamesPlayedAsDefending,
    gamesPlayedAsLandlord: gamesPlayedAsLandlord,
    gamesWon: gamesWon,
    gamesWonAsDefending: gamesWonAsDefending,
    gamesWonAsLandlord: gamesWonAsLandlord,
    ranksUp: ranksUp,
});
export default gameStatistics;

import * as React from "react";
import * as ReactModal from "react-modal";
import IconButton from "./IconButton";
import BarChart from "./icons/BarChart";
import GameStatisticsPane from "./GameStatisticsPane";
import { AppStateContext } from "./AppStateProvider";
var contentStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};
var GameStatisticsButton = function () {
    var _a = React.useState(false), modalOpen = _a[0], setModalOpen = _a[1];
    var state = React.useContext(AppStateContext).state;
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { style: { paddingLeft: "10px" }, onClick: function () { return setModalOpen(true); } },
            React.createElement(BarChart, { width: "2em" })),
        React.createElement(ReactModal, { isOpen: modalOpen, onRequestClose: function () { return setModalOpen(false); }, shouldCloseOnOverlayClick: true, shouldCloseOnEsc: true, style: { content: contentStyle } },
            React.createElement(GameStatisticsPane, { gameStatistics: state.gameStatistics }))));
};
export default GameStatisticsButton;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { AppStateContext } from "./AppStateProvider";
import websocketHandler from "./websocketHandler";
import { TimerContext } from "./TimerProvider";
import memoize from "./memoize";
import WasmContext from "./WasmContext";
export var WebsocketContext = React.createContext({
    send: function () { },
});
var getFileReader = memoize(function () {
    var queue = [];
    var fr = new FileReader();
    fr.onload = function () {
        var next = queue.shift();
        if (next !== undefined) {
            next.handler(fr.result);
            if (queue.length > 0) {
                fr.readAsArrayBuffer(queue[0].blob);
            }
        }
    };
    return {
        enqueue: function (blob, handler) {
            queue.push({ blob: blob, handler: handler });
            if (queue.length > 0 &&
                (fr.readyState === FileReader.EMPTY ||
                    fr.readyState === FileReader.DONE)) {
                fr.readAsArrayBuffer(queue[0].blob);
            }
        },
    };
});
var getBlobArrayBuffer = memoize(function () {
    var queue = [];
    var inflight = [];
    var onload = function (arr) {
        var next = queue.shift();
        if (next !== undefined) {
            inflight.shift();
            next.handler(arr);
            if (queue.length > 0) {
                inflight.push(0);
                queue[0].blob.arrayBuffer().then(onload, function (err) { return console.log(err); });
            }
        }
    };
    return {
        enqueue: function (blob, handler) {
            queue.push({ blob: blob, handler: handler });
            if (inflight.length === 0 && queue.length > 0) {
                inflight.push(0);
                blob.arrayBuffer().then(onload, function (err) { return console.log(err); });
            }
        },
    };
});
var WebsocketProvider = function (props) {
    var _a = React.useContext(AppStateContext), state = _a.state, updateState = _a.updateState;
    var decodeWireFormat = React.useContext(WasmContext).decodeWireFormat;
    var _b = React.useContext(TimerContext), setTimeout = _b.setTimeout, clearTimeout = _b.clearTimeout;
    var _c = React.useState(null), timer = _c[0], setTimer = _c[1];
    var _d = React.useState(null), websocket = _d[0], setWebsocket = _d[1];
    // Because state/updateState are passed in and change every time something
    // happens, we need to maintain a reference to these props to prevent stale
    // closures which may happen if state/updateState is changed between when an
    // event listener is registered and when it fires.
    // https://reactjs.org/docs/hooks-faq.html#why-am-i-seeing-stale-props-or-state-inside-my-function
    var stateRef = React.useRef(state);
    var updateStateRef = React.useRef(updateState);
    var timerRef = React.useRef(timer);
    var setTimerRef = React.useRef(setTimer);
    var setTimeoutRef = React.useRef(setTimeout);
    var clearTimeoutRef = React.useRef(clearTimeout);
    React.useEffect(function () {
        stateRef.current = state;
        updateStateRef.current = updateState;
    }, [state, updateState]);
    React.useEffect(function () {
        setTimeoutRef.current = setTimeout;
        clearTimeoutRef.current = clearTimeout;
    }, [setTimeout, clearTimeout]);
    React.useEffect(function () {
        timerRef.current = timer;
        setTimerRef.current = setTimer;
    }, [timer, setTimerRef]);
    React.useEffect(function () {
        var runtimeWebsocketHost = window._WEBSOCKET_HOST;
        var uri = runtimeWebsocketHost !== undefined && runtimeWebsocketHost !== null
            ? runtimeWebsocketHost
            : (location.protocol === "https:" ? "wss://" : "ws://") +
                location.host +
                location.pathname +
                (location.pathname.endsWith("/") ? "api" : "/api");
        var ws = new WebSocket(uri);
        setWebsocket(ws);
        ws.addEventListener("open", function () {
            return updateStateRef.current({ connected: true, everConnected: true });
        });
        ws.addEventListener("close", function () {
            return updateStateRef.current({ connected: false });
        });
        ws.addEventListener("message", function (event) {
            if (timerRef.current !== null) {
                clearTimeoutRef.current(timerRef.current);
            }
            setTimerRef.current(null);
            var f = function (buf) {
                var message = decodeWireFormat(new Uint8Array(buf));
                if ("Kicked" in message) {
                    ws.close();
                }
                else {
                    updateStateRef.current(__assign({ connected: true, everConnected: true }, websocketHandler(stateRef.current, message, function (msg) {
                        ws.send(JSON.stringify(msg));
                    })));
                }
            };
            if (event.data.arrayBuffer !== undefined) {
                var b2a = getBlobArrayBuffer();
                b2a.enqueue(event.data, f);
            }
            else {
                var frs = getFileReader();
                frs.enqueue(event.data, f);
            }
        });
        return function () {
            if (timerRef.current !== null) {
                clearTimeoutRef.current(timerRef.current);
            }
        };
    }, []);
    var send = function (value) {
        if (timerRef.current !== null) {
            clearTimeoutRef.current(timerRef.current);
        }
        // We expect a response back from the server within 5 seconds. Otherwise,
        // we should assume we have lost our websocket connection.
        var localTimerRef = setTimeoutRef.current(function () {
            if (timerRef.current === localTimerRef) {
                updateStateRef.current({ connected: false });
            }
        }, 5000);
        setTimerRef.current(localTimerRef);
        websocket === null || websocket === void 0 ? void 0 : websocket.send(JSON.stringify(value));
    };
    // TODO(read this from consumers instead of globals)
    window.send = send;
    return (React.createElement(WebsocketContext.Provider, { value: { send: send } }, props.children));
};
export default WebsocketProvider;

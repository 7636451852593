import * as React from "react";
// prettier-ignore
var allRanks = [
    '2', '3', '4', '5', '6', '7', '8',
    '9', '10', 'J', 'Q', 'K', 'A', 'NT'
];
var RankSelector = function (props) {
    var _a = React.useState(false), showMetaRank = _a[0], setShowMetaRank = _a[1];
    var handleChange = function (e) {
        if (e.target.value !== "") {
            props.onChangeRank(e.target.value);
        }
    };
    var handleMetaChange = function (e) {
        if (e.target.value !== "") {
            var v = parseInt(e.target.value, 10);
            props.onChangeMetaRank(v);
        }
    };
    var metaranks = [];
    if (props.metaRank > 0) {
        for (var i = 1; i <= props.metaRank + 3; i++) {
            metaranks.push(i);
        }
    }
    else {
        metaranks.push(props.metaRank);
        metaranks.push(1);
    }
    return (React.createElement("div", { className: "rank-picker" },
        React.createElement("label", null,
            "Your rank:",
            " ",
            React.createElement("select", { value: props.rank, onChange: handleChange }, allRanks.map(function (rank) { return (React.createElement("option", { value: rank, key: rank }, rank)); })),
            React.createElement("input", { type: "checkbox", checked: showMetaRank, onChange: function () { return setShowMetaRank(!showMetaRank); }, title: "show meta-rank" }),
            showMetaRank && (React.createElement("select", { value: props.metaRank, onChange: handleMetaChange }, metaranks.map(function (metarank) { return (React.createElement("option", { value: metarank, key: metarank }, metarank)); }))))));
};
export default RankSelector;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import classNames from "classnames";
import memoize from "./memoize";
import InlineCard from "./InlineCard";
import { cardLookup } from "./util/cardHelpers";
import { SettingsContext } from "./AppStateProvider";
import WasmContext from "./WasmContext";
var SvgCard = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import("./SvgCard")];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); });
var Card = function (props) {
    var settings = React.useContext(SettingsContext);
    var getCardInfo = React.useContext(WasmContext).getCardInfo;
    var height = props.smaller ? 95 : 120;
    var bounds = getCardBounds(height);
    if (!(props.card in cardLookup)) {
        var nonSVG = (React.createElement("div", { className: classNames("card", "unknown", props.className), style: {
                marginRight: props.collapseRight ? "-" + bounds.width * 0.6 + "px" : "0",
            } },
            React.createElement(CardCanvas, { card: props.card, height: height, suit: classNames("unknown", settings.fourColor ? "four-color" : null, settings.darkMode ? "dark-mode" : null), backgroundColor: settings.darkMode ? "#000" : "#fff" })));
        if (settings.svgCards) {
            return (React.createElement(React.Suspense, { fallback: nonSVG },
                React.createElement("div", { className: classNames("card", "svg", "unknown", props.className), style: {
                        marginRight: props.collapseRight
                            ? "-" + bounds.width * 0.6 + "px"
                            : "0",
                    } },
                    React.createElement(SvgCard, { fourColor: settings.fourColor, height: height, card: "🂠" }))));
        }
        else {
            return nonSVG;
        }
    }
    else {
        var cardInfo = cardLookup[props.card];
        var extraInfo_1 = getCardInfo({ card: props.card, trump: props.trump });
        var label = function (offset) { return (React.createElement("div", { className: "card-label", style: { bottom: offset + "px" } },
            React.createElement(InlineCard, { card: props.card }))); };
        var icon = function (offset) { return (React.createElement("div", { className: "card-icon", style: { bottom: offset + "px" } },
            extraInfo_1.effective_suit === "Trump" && settings.trumpCardIcon,
            extraInfo_1.points > 0 && settings.pointCardIcon)); };
        var nonSVG = (React.createElement("div", { className: classNames("card", cardInfo.typ, props.className), onClick: props.onClick, onMouseEnter: props.onMouseEnter, onMouseLeave: props.onMouseLeave, style: {
                marginRight: props.collapseRight ? "-" + bounds.width * 0.6 + "px" : "0",
            } },
            label(bounds.height / 10),
            icon(bounds.height),
            React.createElement(CardCanvas, { card: cardInfo.display_value, height: height, suit: classNames(cardInfo.typ, settings.fourColor ? "four-color" : null, settings.darkMode ? "dark-mode" : null), colorOverride: settings.suitColorOverrides[cardInfo.typ], backgroundColor: settings.darkMode ? "#000" : "#fff" })));
        if (settings.svgCards) {
            return (React.createElement(React.Suspense, { fallback: nonSVG },
                React.createElement("div", { className: classNames("card", "svg", cardInfo.typ, props.className), onClick: props.onClick, onMouseEnter: props.onMouseEnter, onMouseLeave: props.onMouseLeave, style: {
                        marginRight: props.collapseRight
                            ? "-" + bounds.width * 0.6 + "px"
                            : "0",
                    } },
                    label(height / 10),
                    icon(height),
                    React.createElement(SvgCard, { fourColor: settings.fourColor, height: height, card: props.card }))));
        }
        else {
            return nonSVG;
        }
    }
};
var computeCanvasBounds = function (font, dpr) {
    var c = document.createElement("canvas");
    c.style.display = "none";
    document.body.appendChild(c);
    var ctx = c.getContext("2d");
    ctx.scale(dpr, dpr);
    ctx.font = font;
    var text = "🂠";
    var textMetrics = ctx.measureText(text);
    document.body.removeChild(c);
    return textMetrics;
};
var computeSuitColor = function (suit) {
    var c = document.createElement("div");
    c.className = suit;
    c.style.display = "none";
    document.body.appendChild(c);
    var color = getComputedStyle(c).color;
    document.body.removeChild(c);
    return color;
};
var cardBoundsCache = {};
var suitColorCache = {};
var getCardBounds = function (height) {
    var font = height + "px solid";
    if (!(font in cardBoundsCache)) {
        cardBoundsCache[font] = memoize(function () { return computeCanvasBounds(font, 1); });
    }
    var textMetrics = cardBoundsCache[font]();
    var effectiveHeight = Math.round(textMetrics.actualBoundingBoxAscent +
        textMetrics.actualBoundingBoxDescent +
        2);
    var effectiveWidth = Math.round(textMetrics.actualBoundingBoxRight +
        Math.min(textMetrics.actualBoundingBoxLeft, 0) +
        2);
    return {
        metrics: textMetrics,
        height: effectiveHeight,
        width: effectiveWidth,
    };
};
var CardCanvas = function (props) {
    if (!(props.suit in suitColorCache)) {
        suitColorCache[props.suit] = memoize(function () { return computeSuitColor(props.suit); });
    }
    var _a = getCardBounds(props.height), metrics = _a.metrics, width = _a.width, height = _a.height;
    var style = suitColorCache[props.suit]();
    return (React.createElement("svg", { focusable: "false", role: "img", xmlns: "http://www.w3.org/2000/svg", height: height, width: width },
        React.createElement("rect", { fill: props.backgroundColor !== undefined ? props.backgroundColor : "#fff", x: metrics.actualBoundingBoxLeft, y: 0, width: metrics.width - 2, height: height }),
        React.createElement("text", { fill: props.colorOverride !== undefined ? props.colorOverride : style, fontSize: props.height + "px", textLength: width + "px", x: Math.min(metrics.actualBoundingBoxLeft, 0) + 1, y: height - metrics.actualBoundingBoxDescent - 1 }, props.card)));
};
export default Card;

import * as React from "react";
export var WasmContext = React.createContext({
    findViablePlays: function (_, __) { return []; },
    findValidBids: function (_) { return []; },
    sortAndGroupCards: function (_) { return []; },
    decomposeTrickFormat: function (_) { return []; },
    canPlayCards: function (_) { return false; },
    explainScoring: function (_) { return ({ results: [], step_size: 0, total_points: 0 }); },
    nextThresholdReachable: function (_) { return true; },
    computeScore: function (_) { return ({
        score: {
            landlord_won: true,
            landlord_bonus: false,
            landlord_delta: 0,
            non_landlord_delta: 0,
        },
        next_threshold: 0,
    }); },
    computeDeckLen: function (_) { return 0; },
    getCardInfo: function (req) {
        throw new Error("cannot get card info");
    },
    decodeWireFormat: function (_) {
        throw new Error("cannot decode wire format");
    },
});
export default WasmContext;

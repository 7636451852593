import * as React from "react";
import { AppStateContext } from "./AppStateProvider";
export var DebugInfo = function (_props) {
    var appState = React.useContext(AppStateContext);
    return (React.createElement("pre", null, JSON.stringify({
        gameState: appState.state.gameState,
        settings: appState.state.settings,
        roomName: appState.state.roomName,
    }, null, 2)));
};
export default DebugInfo;

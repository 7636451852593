import * as React from "react";
var CheckpointCircle = function (props) {
    return (React.createElement("div", { style: {
            position: "relative",
            left: props.position,
            transform: "translate(-50%, 0%)",
            backgroundColor: props.color,
            marginTop: props.marginTop,
            height: "30px",
            width: "30px",
            borderWidth: "5px",
            borderStyle: "solid",
            borderColor: props.borderColor !== undefined ? props.borderColor : props.color,
            borderRadius: "25px",
        } },
        React.createElement("div", { style: {
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            } }, props.text)));
};
var convertToPercentage = function (proportion) {
    proportion = Math.max(0, Math.min(1, proportion));
    return (100 * proportion).toFixed(2) + "%";
};
var ProgressBar = function (props) {
    var landlordColor = "#d9534f";
    var challengerColor = "#5bc0de";
    var neutralColor = "lightgray";
    var totalPoints = props.totalPoints, challengerPoints = props.challengerPoints, landlordPoints = props.landlordPoints;
    var checkpointColors = props.checkpoints.map(function (checkpoint) {
        if (challengerPoints >= checkpoint) {
            return challengerColor;
        }
        else if (landlordPoints >= totalPoints - checkpoint) {
            return landlordColor;
        }
        else {
            return neutralColor;
        }
    });
    var landlordPosition = convertToPercentage((totalPoints - landlordPoints) / totalPoints);
    var landlordWidth = convertToPercentage(landlordPoints / totalPoints);
    var challengerPosition = convertToPercentage(challengerPoints / totalPoints);
    return (React.createElement("div", { style: { color: "#000", padding: "0px 5px" } },
        React.createElement("div", { style: {
                width: "100%",
                borderRadius: "5px",
                backgroundColor: "lightgray",
            } },
            React.createElement("div", { style: {
                    width: challengerPosition,
                    height: "20px",
                    borderRadius: "5px",
                    backgroundColor: challengerColor,
                } }),
            !props.hideLandlordPoints && (React.createElement("div", { className: "progress-bar-landlord", style: {
                    marginTop: "-20px",
                    position: "relative",
                    left: landlordPosition,
                    width: landlordWidth,
                    height: "20px",
                    borderRadius: "5px",
                    backgroundColor: landlordColor,
                } }))),
        props.checkpoints.map(function (checkpoint, i) {
            return (React.createElement(CheckpointCircle, { key: i, text: checkpoint, color: checkpointColors[i], position: convertToPercentage(checkpoint / totalPoints), marginTop: i === 0 ? "-30px" : "-40px" }));
        }),
        React.createElement(CheckpointCircle, { text: challengerPoints, color: "#fff", borderColor: challengerColor, position: challengerPosition, marginTop: "-40px" }),
        !props.hideLandlordPoints && (React.createElement(CheckpointCircle, { text: totalPoints - landlordPoints, color: "#fff", borderColor: landlordColor, position: landlordPosition, marginTop: "-40px" }))));
};
export default ProgressBar;
